import React from 'react'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import heading from '../styles/heading'

const StyledHeading = styled.h2`
  ${heading}
  line-height: 1.1;

  .bottom-border {
    margin-top: 10px;
  } 

  ${props => fluidRange(
    [{
      prop: 'font-size',
      fromSize: props.theme.fontSizes.large.min,
      toSize: props.theme.fontSizes.large.max,
    },
    {
      prop: 'margin-bottom',
      fromSize: '10px',
      toSize: '20px',
    }],
    '800px',
    '1000px',
  )}
`
const HeadingJumbo = props => {

    let postBorder;
    let cssClass;
  
    if (props.showBorder) {
        postBorder = <div className="bottom-border" />;
    } 

    if (props.center) {
        cssClass += " center";
      } 
    

  return (
    <StyledHeading className={cssClass}>
      {props.children}
      {postBorder}
    </StyledHeading>
  )
}

export default HeadingJumbo
