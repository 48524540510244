import React from 'react'
import styled from 'styled-components'
import SidebarMenuBullet from './SidebarMenuBullet'
import { Home } from '@material-ui/icons'

const StyledHome = styled(Home)`
    font-size: 12px;
`
const SidebarMenuItem = (props) => {
    let spanClass;
    let icon = <SidebarMenuBullet />
    if (props.section.anchor === "home") {
        spanClass = "sr-only";
        icon = <StyledHome fontSize="inherit" />
    }

  return (
    <li>
        <a data-menuanchor={props.section.anchor} href={"#" + props.section.anchor} onClick={props.click}>
            {icon}
            <span className={spanClass}>{props.section.title}</span>
        </a>
    </li>
  )
}

export default SidebarMenuItem
