import React from 'react'
import styled from 'styled-components'

const Bullet = styled.div`
          height 1.6rem;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;          
`

const Outer = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; 
    border: .1rem solid rgba(${props => props.theme.rgbs.primary},0);
    transition: border-color ${props => props.theme.transitions.default};
`

const Inner = styled.div`
    width .5rem;
    height .5rem;
    margin: 0 auto;
    background: ${props => props.theme.colors.bullet};
    border-radius: 50%;
    transition: background-color ${props => props.theme.transitions.default};
`

const SidebarMenuBullet = () => {
  return (
    <Bullet>
        <Outer className="outer">
            <Inner className="inner" />
        </Outer>
    </Bullet>
  )
}

export default SidebarMenuBullet
