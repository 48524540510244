import React from 'react'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import heading from '../styles/heading'

const StyledHeading = styled.h3`
  ${heading}
  font-style: italic;
  
  ${props => fluidRange(
    [{
      prop: 'font-size',
      fromSize: props.theme.fontSizes.medium.min,
      toSize: props.theme.fontSizes.medium.max,
    },
    {
      prop: 'margin-bottom',
      fromSize: '5px',
      toSize: '10px',
    }],
    '800px',
    '1000px',
  )}
`
const HeadingMedium = props => {
  let cssClass = "medium";
  if (props.center) {
    cssClass += " center";
  }

  return (
    <StyledHeading className={cssClass}>
      {props.children}
    </StyledHeading>
  )
}

export default HeadingMedium
