import React from 'react'
import { ThemeProvider } from 'styled-components'
import { StaticQuery, graphql} from 'gatsby'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import config from '../utils/siteConfig'
import styled from 'styled-components'
import DiamondLogo from "!svg-react-loader!../images/logos/diamond.svg"
import SidebarMenu from './SidebarMenu'
import breakpoints from '../styles/breakpoints'
import Video from '../components/ResponsiveVideo'
import Vignette from '../components/Vignette'
import Div100vh from 'react-div-100vh'

const StyledDiamondLogo = styled(DiamondLogo)`
  width: 75px;
  position: fixed;
  top: 20px;
  left: 26px;
  right: 0;

  @media ${breakpoints.tablet} {
    width: 90px;
    top: 35px;
    margin: 0 auto;
  }     
`

const VideoContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`

export default (props) => (
  <StaticQuery
    query={graphql`
      query Video {
        contentfulHomeScreen {        
          backgroundVideo {
            file {
              url
            }
          }
        }
      }
    `} 
    render={data => (
      <Div100vh className="siteRoot">
        <Helmet>
          <title>{config.siteTitle}</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href={favicon} />
        </Helmet>
        
        

        <ThemeProvider theme={theme}>
          <>
            <VideoContainer>
              <Video url={data.contentfulHomeScreen.backgroundVideo.file.url} />
              <Vignette />
            </VideoContainer>

            <div className="siteContent">
              {props.children}
            </div>
            <SidebarMenu structure={props.structure}></SidebarMenu>
          </>
        </ThemeProvider>

        <StyledDiamondLogo />

        <GlobalStyle />
      </Div100vh>
    )}
  />
)
