import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { fluidRange } from 'polished'
import HeadingLarge from '../components/HeadingLarge'

/*
  ⚠️ This is an example of a contact form powered with Netlify form handling.
  Be sure to review the Netlify documentation for more information:
  https://www.netlify.com/docs/form-handling/
*/

const Form = styled.form`
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  z-index: 999999;
  margin: 0 auto;
  padding-top: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  input,
  textarea {
    outline: none;
    background: rgba(0,0,0,.2);
    color: white;
    border: 1px solid #7a8284;
    padding: 1em;

    &,
    &::placeholder {
      font-family ${props => props.theme.fonts.sans};
      letter-spacing: .1rem;
      font-size: 1.4rem;
      font-weight: 300;      
      color: white;
    }
    &::placeholder {
      text-transform: uppercase;
    }
    &:required {
      box-shadow: none;
    }
    &:focus {
      outline: none;
    }
  }
  &::before {
    content: '';
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.2s all;
    opacity: ${props => (props.overlay ? '.8' : '0')};
    visibility: ${props => (props.overlay ? 'visible' : 'hidden')};
  }
`

const Name = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
`

const Email = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
`

const Message = styled.textarea`
  width: 100%;
  margin: 0 0 1em 0;
  line-height: 1.6;
  min-height: 50px;
  resize: none;

  ${props => fluidRange(
    {
      prop: 'min-height',
      fromSize: '100px',
      toSize: '250px',
    },
    '800px',
    '2000px',
  )}  
`

const Submit = styled(Button)`
  background: ${props => props.theme.colors.primary} !important;
  border-radius: 0 !important;
  width: 100%;
  box-shadow: none !important;

  &,
  span {
    font-family ${props => props.theme.fonts.sans} !important;
    font-size: 16px !important;
    color: white !important;    
  }
`

const Modal = styled.div`
  background: white;
  color: black;
  padding: 2em;
  border-radius: 0;
  position: absolute;
  min-width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin: 0 auto;
  z-index: 99;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  transition: 0.2s all;
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    min-width: inherit;
    max-width: 600px;
  }
  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }
`



const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      showModal: false,
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contactform', ...this.state }),
    })
      .then(this.handleSuccess)
      .catch(error => alert(error))
    event.preventDefault()
  }

  handleSuccess = () => {
    this.setState({
      name: '',
      email: '',
      message: '',
      showModal: true,
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <Form
        name="contactform"
        onSubmit={this.handleSubmit}
        data-netlify="true"
        data-netlify-honeypot="bot"
        overlay={this.state.showModal}
        onClick={this.closeModal}
      >
        <input type="hidden" name="form-name" value="contactform" />
        <p hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot" onChange={this.handleInputChange} />
          </label>
        </p>

        <Name
          name="name"
          type="text"
          placeholder="Name"
          value={this.state.name}
          onChange={this.handleInputChange}
          required
        />
        <Email
          name="email"
          type="email"
          placeholder="Email Address"
          value={this.state.email}
          onChange={this.handleInputChange}
          required
        />
        <Message
          name="message"
          type="text"
          placeholder="Message"
          value={this.state.message}
          onChange={this.handleInputChange}
          required
        />

        <Submit name="submit" type="submit" variant="contained">Send</Submit>        

        <Modal visible={this.state.showModal}>
            <HeadingLarge center>Contact Successful</HeadingLarge>
            
            <p>Thanks for getting in contact. We will get back to you as soon as possible.</p>
            <Submit onClick={this.closeModal}>Close</Submit>
        </Modal>
      </Form>
    )
  }
}

ContactForm.propTypes = {
  data: PropTypes.object,
}

export default ContactForm
