import React, {Component} from 'react'
import SidebarMenuItem from './SidebarMenuItem'
import styled from 'styled-components'
import breakpoints from '../styles/breakpoints'

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  right: auto;
  background-color: rgba(0,0,0,0);
  width: 100%;
  transition: background-color ${props => props.theme.transitions.default};
  z-index: 1;

  &.menu-active {
    background-color: rgba(0,0,0,.4);
    left: 0;

    .nav-container {
      transform: translateX(0) !important;

      nav {
        .menu {
          ul {
            li {
              a {
                span {
                  color: #999;
                  opacity: 1;      
                  
                  @media ${breakpoints.xl} {
                    color: #565656;
                  }                   
                }

                &:hover,
                &.active {
                  span {
                    color: ${props => props.theme.colors.primary};
                  }
                }                
              }
            }
          }
        }
      }
    }
  }

  .burger-btn {
    position: fixed;
    top: 30px;
    left: auto;
    right: 26px;
    z-index: 1100;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(${props => props.theme.rgbs.primary}, 0);
    transition: background-color ${props => props.theme.transitions.default};
    cursor: pointer;


    .burger {
      div {
        width: 25px;
        height: 1px;
        background: white;
        margin-bottom: 5px;

        &:last-child {
          width: 17px;
          margin-bottom: 0;
        }
      }
    }

    &:hover {
      background-color: rgba(${props => props.theme.rgbs.primary}, 0.1)
    }

  }

  .nav-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    background: black;
    width: 30rem;
    transform: translateX(-30rem);
    transition: transform ${props => props.theme.transitions.default};
  
    nav {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: auto;    
      width: 10rem;
      display: flex;
  
      flex-direction: column;
      z-index: 100;
  
      @media ${breakpoints.xl} {
        display: flex;
      }  
  
      svg {
        color: #565656;
        color ${props => props.theme.transitions.default}; 
      }

      .border {
        flex-grow: 2;
  
        .inner {
          width: 1px;
          background: #565656;
          height: 100%;
          margin: 0 auto;
        }
      }
  
      .menu {
        padding-top: 4.4rem;
        padding-bottom: 4.4rem;
  
        ul {
          text-align: center;
          li {
            position: relative;
            margin-bottom: 4rem;
  
            &:last-child{
              margin-bottom: 0;
            }
  
            a {
              span {
                display: block;
                position: absolute;
                top: 0;
                left: 75%;
                width: 10rem;
                text-align: left;
                opacity: 0;
                color: ${props => props.theme.colors.primary};
                font-family: ${props => props.theme.fonts.sans};
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: .2rem;
                font-size: 1rem;  
                line-height: 1.6rem;          
                transition: opacity ${props => props.theme.transitions.default}, color ${props => props.theme.transitions.default};                
              }
  
              &:hover,
              &.active {
                span {
                  opacity: 1;
                }
  
                svg {
                  color: ${props => props.theme.colors.primary};
                }
                .outer {
                  border-color: rgba(${props => props.theme.rgbs.primary},255);
                }
  
                .inner {
                  background-color: white;
                }
                
              }
  
              
            }
  
  
          }
  
        }
      }    
  
    }    


  }

  @media ${breakpoints.xl} {
    &,
    &.menu-active {
      background-color: rgba(0,0,0,0);

      .burger-btn {
        display: none;
      }
      .nav-container {
        background: none;
        transition: none;
        transform: none;
      }
    }
  }     
`

class SidebarMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false
    }
  }

  toggleNav = () => {
    const currentState = this.state.menuActive;
    this.setState({ menuActive: !currentState });
  }

  DeactivateNav = () => {
    this.setState({ menuActive: false });
  }

  render() {
    return (


      <MenuContainer className={this.state.menuActive ? 'menu-active': null}>
        <button className="burger-btn" onClick={this.toggleNav} >
          <span className="sr-only">Menu</span>
          <div className="burger">
            <div />
            <div />
            <div />
          </div>          
        </button>
    
        <div className="nav-container">
          <nav id="sidebarMenu">
            <div className="border"><div className="inner"></div></div>
            <div className="menu">
              <ul>

                {this.props.structure.map(section => {
                  return (
                    <SidebarMenuItem key={"menu-item-" + section.anchor} section={section} click={this.DeactivateNav}/>
                  )
                })}
              </ul>
            </div>
            <div className="border"><div className="inner"></div></div>
          </nav>
        </div>
    
      </MenuContainer>

    )  
  }
}

export default SidebarMenu
