import React from 'react'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import breakpoints from '../styles/breakpoints'

const Styled = styled.div`
    &,
    p {

        ${props => fluidRange(
            {
              prop: 'font-size',
              fromSize: '22px',
              toSize: '30px',
            },
            '800px',
            '1000px',
          )}

        
        font-family: ${props => props.theme.fonts.serif};
        font-weight: 400;
        line-height: 1;    
        
        @media ${breakpoints.tablet} {
            line-height: 1.2;
        }     
        @media ${breakpoints.desktop} {
            line-height: 1.3;
        }               

    }   
`
const LargeText = props => {
    return (
        <Styled>
            { props.children }
        </Styled>  
    )
}

export default LargeText