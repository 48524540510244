import React from 'react'
import { StaticQuery, graphql} from 'gatsby'
import { Grid, Row, Col } from 'react-flexbox-grid';
import FullPageSection from '../../components/FullPageSection'
import SectionContainer from '../../components/SectionContainer'
import ContentWrapper from '../../components/ContentWrapper'
import ContactForm from '../../components/ContactForm'
import HeadingJumbo from '../../components/HeadingJumbo'



export default () => (
  <StaticQuery
    query={graphql`
    query Contact {
        contentfulContact {
          title
          verticalText
        }
      }
    `}
    render={data => (
      <FullPageSection>
        <SectionContainer>

          <ContentWrapper verticalText={data.contentfulContact.verticalText}>

            
            <Grid fluid>
              <Row>
                <Col>
                    <HeadingJumbo showBorder center>{data.contentfulContact.title}</HeadingJumbo>                    
                    <ContactForm />
                </Col>
              </Row>
            </Grid>            
            
          </ContentWrapper>

        </SectionContainer> 
      </FullPageSection>
    )}
  />
)