import {css} from 'styled-components'
import breakpoints from '../styles/breakpoints';

const heading = css`
  font-family: ${props => props.theme.fonts.serif};
  font-weight: 400;
  line-height: 1.2;

  .bottom-border {
    width: 5rem;
    height: 1px;
    background: ${props => props.theme.colors.primary};
    margin-top: 10px;
  }  

  &.center {
    @media ${breakpoints.tablet} {
      text-align: center;
      
      &,
      .bottom-border {
        margin-left: auto;
        margin-right: auto;
      }
      
    }  
  }
`

export default heading
